import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Empresa } from '../types/empresa';


export interface EmpresaStore {
    empresa?: Empresa | null,
    setEmpresa: (empresa: Empresa) => void,
}

export const useEmpresaStore = create(
    persist<EmpresaStore>(
        (set, get) => ({
            empresa: null,
            setEmpresa: (empresa: Empresa) => set({ empresa }),
        }),
        {
            name: 'dcx:intra:empresa', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    ),
);