
//@ts-nocheck

import PocketBase, { CommonOptions, RecordListOptions, RecordOptions } from "pocketbase";
import { COLLECTION } from "../types/pocketbase";
import { constants } from "../utils/contants";

export interface Error {
    data: {
        code: number,
        message: string
    }
}

export const pb = new PocketBase(constants.global.api_url);

export const useColletion = (collection: COLLECTION) => {



    const getDoc = async  <T = any>(id: string, options: RecordOptions | undefined) => {
        let doc = await pb.collection(collection).getOne<T>(id, options);
        return doc;
    };
    const getDocs = async <T = any>(page?: number | undefined, perPage?: number | undefined, options?: RecordListOptions | undefined) => {
        let doc = await pb.collection(collection).getList<T>(page, perPage, options);
        return doc;
    };
    const addDoc = async <T = any>(bodyParams?: {
        [key: string]: any;
    } | FormData | undefined, options?: RecordOptions | undefined): Promise<T | Error> => {
        let doc = await pb.collection(collection).create<T>(bodyParams, options);
        return doc;
    };
    const updateDoc = async  <T = any>(id: string, bodyParams?: { [key: string]: any; } | FormData | undefined, options?: RecordOptions | undefined): Promise<T | Error> => {
        let doc = pb.collection(collection).update<T>(id, bodyParams, options);
        return doc;
    };
    const deleteDoc = async (id: string, options?: CommonOptions | undefined): Promise<boolean | Error> => {
        let doc = pb.collection(collection).delete(id, options);
        return doc;
    };
    return {
        pb,
        getDoc,
        getDocs,
        addDoc,
        updateDoc,
        deleteDoc,
    }
}


export const usePocketBase = () => {

    const useFileUrl = <T = any>(item: T, campo: keyof T) => {
        if (item[campo] instanceof Array) {
            if (item[campo]?.length > 0) {
                let items: string[] = [];
                Array.from(item[campo])?.forEach((f, k) => {
                    //@ts-ignore
                    items.push(`${constants.global.api_url}/api/files/${item.collectionId}/${item.id}/${item[campo][k]}/`)
                });
                return items;
            } else {
                return [];
            }
        } else {
            //@ts-ignore
            return `${constants.global.api_url}/api/files/${item.collectionId}/${item.id}/${item[campo]}/`
        }
    }


    return {
        useFileUrl,
    }
}