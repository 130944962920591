import {
  Center,
  Spinner
} from "@chakra-ui/react";
import { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

export const App = () => {

  const LoginPage = lazy(() => import('./pages/page-login'));
  const ReconnectPage = lazy(() => import('./pages/page-reconnect'));
  const ChatPage = lazy(() => import('./pages/page-chat'));
  const MeuPerfilPage = lazy(() => import('./pages/page-perfil'));
  const DashboardPage = lazy(() => import('./pages/page-home'));
  const QualidadePage = lazy(() => import('./pages/page-qualidade'));
  const NotificacoesPage = lazy(() => import('./pages/page-notificacoes'));
  const TarefasPage = lazy(() => import('./pages/page-tarefas'));
  const ComunicadoInternoPage = lazy(() => import('./pages/page-ci'));
  const RequireAuthPage = lazy(() => import('./pages/page-require-auth'));
  const CadastrosPage = lazy(() => import('./pages/page-cadastros'));
  const AssinaturaPage = lazy(() => import('./pages/page-assinatura'));
  const HelpdeskPage = lazy(() => import('./pages/helpdesk'));
  const AgendaPage = lazy(() => import('./pages/page-agenda'));
  const AgendaIdPage = lazy(() => import('./pages/page-agenda/agenda'));
  const PacientesPage = lazy(() => import('./pages/page-agenda/agendamentos'));
  const LogsPage = lazy(() => import('./pages/page-logs'));

  return (
    <Router>
      <Suspense fallback={
        <Center
          w={`100vw`}
          h={`100vh`}
        >
          <Spinner size={`xl`} color='cyan.500' />
        </Center>}>
        <Routes>
          <Route path='/'>
            <Route element={<RequireAuthPage />}>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/perfil" element={<MeuPerfilPage />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/qualidade" element={<QualidadePage />} />
              <Route path="/notificacoes" element={<NotificacoesPage />} />
              <Route path="/tarefas" element={<TarefasPage />} />
              <Route path="/ci" element={<ComunicadoInternoPage />} />
              <Route path="/assinatura" element={<AssinaturaPage />} />
              <Route path="/cadastros" element={<CadastrosPage />} />
              <Route path="/agenda" element={<AgendaPage />} />
              <Route path="/agenda/:id/agendamentos" element={<PacientesPage />} />
              <Route path="/agenda/:id" element={<AgendaIdPage />} />
              <Route path="/logs" element={<LogsPage />} />
              <Route path="/helpdesk/:department/:status" element={<HelpdeskPage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reconnect" element={<ReconnectPage />} />
            <Route path="*" element={<RequireAuthPage />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  )
}
