import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Area } from '../types/area';
import { geraDataSelect } from '../utils/functions/gera-data';


export interface AreasStore {
    areas?: Area[] | null;
    areas_select: { label: string, value: string, rest?: any }[];
    setAreas: (areas: Area[]) => void;
    delAreas: () => void;
    addArea: (aviso: Area) => void;
    delArea: (aviso: Area) => void;
}

export const useAreasStore = create(
    persist<AreasStore>(
        (set, get) => ({
            areas: null,
            areas_select: [],
            setAreas: (areas: Area[]) => {
                let areas_select = geraDataSelect(areas);
                set({ areas, areas_select })
            },
            delAreas: () => set({ areas: null }),
            addArea: (tarefa: Area) => set({ areas: [...get()?.areas as Area[], tarefa] }),
            delArea: (tarefa: Area) => set({ areas: get()?.areas?.filter((f) => f?.id !== tarefa?.id) }),
        }),
        {
            name: 'dcx:intra:areas', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    ),
);