import { removeAcentos } from "./remove-acentos";

export const geraDataSelect = <T = any>(data: T[], action?: React.Dispatch<T>) => {

    let dt: { label: string, value: string, rest: any }[] = [];

    data?.forEach((i: any) => {
        dt.push({ label: i.nome, value: i.id, rest: i })
    })
    dt?.sort((a, b) => {
        if (removeAcentos(a?.label?.toLowerCase()) < removeAcentos(b?.label?.toLowerCase())) {
            return -1;
        } else {
            return 1;
        }
    });

    if (action) {
        //@ts-ignore
        action(dt);
    }
    return dt;
}