import { RecordListOptions } from "pocketbase";
import { Centrocusto } from "../types/centrocusto";
import { pb } from "./usePocketBase";


export const useCentrocustosDB = () => {

    const allCentrocustos = async (options?: RecordListOptions) => {
        return (await pb.collection('centrocustos').getList<Centrocusto>(1, 999, { filter: `ativo=${false}&${options?.filter}`, ...options }))?.items

    }

    return {
        allCentrocustos,
    }
}