import { jwtDecode } from "jwt-decode";
import ms from "ms";
import PocketBase, { RecordAuthResponse, RecordModel } from "pocketbase";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { useInterval } from "usehooks-ts";
import { useAreasDB } from "../hooks/useAreas";
import { useAvisosDB } from "../hooks/useAvisos";
import { useCentrocustosDB } from "../hooks/useCentrocustos";
import { useChamado } from "../hooks/useChamado";
import { useColletion } from "../hooks/usePocketBase";
import { AuthLoginProps, Usuario } from "../types/auth";
import { constants } from "../utils/contants";
import { useAreasStore } from "../zustand/useAreas";
import { useAuthStore } from "../zustand/useAuthStore";
import { useAvisosStore } from "../zustand/useAvisos";
import { useCentrocustosStore } from "../zustand/useCentrocusto";
import { useEmpresaStore } from "../zustand/useEmpresaStore";

const BASE_URL = constants.global.api_url;
const fiveMinutesInMs = ms("5 minutes");
const twoMinutesInMs = ms("2 minutes");


export interface AuthContextProps {
    login: ({ username, password }: AuthLoginProps) => Promise<RecordAuthResponse<RecordModel> | any>,
    logout: () => void,
}

const AuthContext = createContext<AuthContextProps>({
    login: async () => { },
    logout: () => { },
});

export const AuthProvider: React.FC<any> = ({ children }) => {
    const pb = useMemo(() => new PocketBase(BASE_URL), []);

    const { getDocs } = useColletion('empresas');

    const { allAvisos } = useAvisosDB();
    const { allAreas } = useAreasDB();
    const { allCentrocustos } = useCentrocustosDB();


    const { empresa, setEmpresa } = useEmpresaStore();
    const { usuario, setUsuario, isLogged, delUsuario } = useAuthStore();
    const { avisos, setAvisos } = useAvisosStore();
    const { areas, setAreas } = useAreasStore();
    const { centrocustos, setCentrocustos } = useCentrocustosStore();

    const [token, setToken] = useState(pb.authStore.token);
    const [user, setUser] = useState<Usuario | any>(pb.authStore.model as Usuario);

    const { getChamados } = useChamado();


    useEffect(() => {
        if (user) {
            setUsuario(user);
        }
        if (!empresa || !empresa?.id) {
            getDocs()
                .then((empresa) => {
                    setEmpresa(empresa?.items[0])
                })
        }
    }, [])

    useEffect(() => {
        if (isLogged) {
            setUsuario(user)
            return pb.authStore.onChange((token, model) => {
                setToken(token);
                setUser(model as Usuario);
            });
        }
    }, []);

    let session = localStorage.getItem('pocketbase_auth');
    useEffect(() => {

        if (session && JSON.parse(session)?.token) {
            setToken(JSON.parse(session)?.token);
        }
        if (session && JSON.parse(session)?.model) {
            setUsuario(JSON.parse(session)?.model);
        }
    }, [])

    useEffect(() => {
        if (isLogged) {
            if (!avisos) {
                (async () => {
                    let av = await allAvisos();
                    setAvisos(av)
                });
            }
        }

        if (!areas) {
            (async () => {
                let ars = await allAreas();
                setAreas(ars);
            })
        }

        if (!centrocustos) {
            (async () => {
                let cc = await allCentrocustos();
                setCentrocustos(cc);
            })
        }
    }, [])

    const refreshSession = useCallback(async () => {
        if (!pb.authStore.isValid) return;
        const decoded = jwtDecode(token);
        const tokenExpiration = decoded.exp as number;
        if (decoded?.exp) {
            const expirationWithBuffer = (decoded?.exp + fiveMinutesInMs) / 1000;
            if (tokenExpiration < expirationWithBuffer) {
                await pb.collection("users").authRefresh();
            }
        }
    }, [token]);

    useInterval(refreshSession, token ? twoMinutesInMs : null);

    const login = useCallback(async ({ username, password }: AuthLoginProps) => {
        const auth = await pb.collection<Usuario>("users").authWithPassword<Usuario>(username, password, { expand: 'centrocusto,empresa,tarefas' })
        setUser(auth.record);
        setToken(auth.token);

        pb.collection('users').update(auth?.record?.id, { data_last_login: new Date() })

        return auth;
    }, []);

    const logout = useCallback(() => {
        pb.authStore.clear();
        delUsuario();

    }, []);
    return (
        <AuthContext.Provider
            //@ts-ignore
            value={{ login, logout }}
        >
            {children}
        </AuthContext.Provider>
    )
};

export const useAuth = () => useContext(AuthContext);

