import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Usuario } from '../types/auth';


export interface AuthStore {
    isLogged: boolean;
    isStandby: boolean;
    token?: string | null;
    usuario?: Partial<Usuario> | null;
    setToken: (token: string) => void;
    setUsuario: (usuario: Usuario) => void;
    delUsuario: () => void;
    upUsuario: (usuario: Partial<Usuario>) => void;
    addStandby: () => void;
    delStandby: () => void;
}

export const useAuthStore = create(
    persist<AuthStore>(
        (set, get) => ({
            isLogged: false,
            isStandby: false,
            usuario: null,
            setToken: (token: string) => set({ token }),
            setUsuario: (usuario: Usuario) => set({ usuario, isLogged: true, isStandby: false }),
            delUsuario: () => {
                set({ usuario: null, isLogged: false, isStandby: false });
            },
            upUsuario: (usuario: Partial<Usuario>) => set({ usuario: { ...get()?.usuario as Usuario, ...usuario } }),
            addStandby: () => {
                localStorage.removeItem('dcx:intra:tasks');
                localStorage.removeItem('dcx:intra:chat');
                localStorage.removeItem('pocketbase_auth');
                set({ isLogged: false, token: undefined, usuario: { username: get().usuario?.username }, isStandby: true });
            },
            delStandby: () => {
                set({ isStandby: false });
            },
        }),
        {
            name: 'dcx:intra:auth', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    ),
);