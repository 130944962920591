import { Badge, Center } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { BsCheck2All } from "react-icons/bs";
import { FaPersonWalking } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { MdTimer } from "react-icons/md";
import { RiFileWarningFill } from "react-icons/ri";
import { PocketBaseDefaultItem } from ".";
import { Usuario } from "./auth";
import { Centrocusto } from "./centrocusto";
import { Empresa } from "./empresa";
import { Equipamento } from "./equipamento";
import { Fornecedor } from "./fornecedor";

export enum PrioridadeChamado {
    baixa,
    media,
    alta
}


export const expandChamado = { expand: 'usuario,centrocusto,fornecedor,empresa,equipamento,tecnico_inicia_atendimento,tecnico_inicia_pendencia,tecnico_finaliza_atendimento,pendencia_empresa,evolucoes,evolucoes.usuario,evolucoes.chamado' }

export type StatusChamado = 'aberto' |
    'atendimento' |
    'pendente' |
    'finalizado' |
    'aguardando'

export const colors: { [P in StatusChamado]: string } = {
    'aguardando': 'linear-gradient(105deg, rgba(187,37,0,1) 44%, rgba(255,149,0,1) 91%);',
    'aberto': 'linear-gradient(210deg, rgba(0,17,32,1) 0%, rgba(4,167,187,1) 100%);',
    'atendimento': 'linear-gradient(210deg, rgba(32,0,0,1) 0%, rgba(187,4,4,1) 100%);',
    'pendente': 'linear-gradient(210deg, rgba(32,28,0,1) 0%, rgba(223,217,3,1) 100%);',
    'finalizado': 'linear-gradient(210deg, rgba(0,32,0,1) 0%, rgba(4,187,46,1) 100%);',
}

export const bgcolors: { [P in StatusChamado]: string } = {
    'aguardando': 'linear-gradient(105deg, rgba(187,37,0,1) 44%, rgba(255,149,0,1) 91%);',
    'aberto': ' linear-gradient(210deg, rgba(3,144,223,0,0.55), rgba(3,144,223,0.25) 100%)',
    'atendimento': 'linear-gradient(210deg, rgba(223,3,3,0.55), rgba(223,3,3,0.25) 100%)',
    'pendente': 'linear-gradient(210deg, rgba(223,217,3,0.55),rgba(223,217,3,0.25) 100%)',
    'finalizado': 'linear-gradient(210deg, rgba(3,223,21,0.55), rgba(3,223,21,0.25) 100%)'
}

export let icons: { [P in StatusChamado]: IconType } = {
    'aguardando': MdTimer,
    'aberto': FiSend,
    'atendimento': FaPersonWalking,
    'pendente': RiFileWarningFill,
    'finalizado': BsCheck2All
}

export const StatusChamado = ({ status }: { status: StatusChamado }) => {
    return <Center><Badge color={`white`} bg={colors[status]}>{status}</Badge></Center>
}

export interface Evolucao extends PocketBaseDefaultItem<{ usuario: Usuario, chamado: Chamado, empresa: Empresa }> {
    anexos?: File[],
    mensagem?: string,
    usuario?: string,
    chamado?: string,
    empresa?: string,
}


export interface ChamadoCategoria extends PocketBaseDefaultItem{
    nome: string,
    ativo: boolean,
}


export interface Chamado extends PocketBaseDefaultItem<{
    empresa: Empresa,
    centrocusto: Centrocusto,
    fornecedor?: Fornecedor,
    usuario: Usuario,
    evolucoes?: Evolucao[],
    pendencia_empresa?: Fornecedor,
    tecnico_inicia_atendimento?: Usuario,
    tecnico_inicia_pendencia?: Usuario
    tecnico_finaliza_atendimento?: Usuario,
    equipamento?: Equipamento,
    categoria?: ChamadoCategoria,
}> {
    codigo?: string,
    titulo?: string,
    nome?: string,
    descricao?: string,
    status?: StatusChamado,
    prioridade?: PrioridadeChamado,
    data_abertura?: Date | string,
    data_inicio_atendimento?: Date | string,
    data_finaliza_atendimento?: Date | string,
    anexos?: File[],
    pendencia_anexos?: File[],
    pendencia_codigo?: string,
    pendencia_data_inicio?: Date,
    pendencia_data_previsao_retorno?: Date,
    pendencia_motivo?: string,
    pendencia_data_retorno?: Date,
    pendencia_observacoes_retorno?: string,
    avaliacao?: boolean,
    avaliacao_nota_atendimento?: number,
    avaliacao_observacao?: string,
    usuario?: string,
    centrocusto?: string,
    fornecedor?: string,
    evolucoes?: Evolucao[],
    empresa?: string,
    equipamento?: Equipamento,
    categoria?: ChamadoCategoria,
    tecnico_inicia_atendimento?: string,
    tecnico_inicia_pendencia?: string,
    tecnico_finaliza_atendimento?: string,
    pendencia_empresa?: string,
}