import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Centrocusto } from '../types/centrocusto';
import { geraDataSelect } from '../utils/functions/gera-data';


export interface CentrocustosStore {
    centrocustos?: Centrocusto[] | null;
    centrocustos_select: { label: string, value: string, rest: any[] }[];
    setCentrocustos: (centrocustos: Centrocusto[]) => void;
    delCentrocustos: () => void;
    addCentrocusto: (aviso: Centrocusto) => void;
    delCentrocusto: (aviso: Centrocusto) => void;
}

export const useCentrocustosStore = create(
    persist<CentrocustosStore>(
        (set, get) => ({
            centrocustos: null,
            centrocustos_select: [],
            setCentrocustos: (centrocustos: Centrocusto[]) => {
                let centrocustos_select = geraDataSelect(centrocustos);
                set({ centrocustos, centrocustos_select })
            },
            delCentrocustos: () => set({ centrocustos: null }),
            addCentrocusto: (centrocusto: Centrocusto) => set({ centrocustos: [...get()?.centrocustos as Centrocusto[], centrocusto] }),
            delCentrocusto: (centrocusto: Centrocusto) => set({ centrocustos: get()?.centrocustos?.filter((f) => f?.id !== centrocusto?.id) }),
        }),
        {
            name: 'dcx:intra:centrocustos', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    ),
);