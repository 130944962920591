import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Aviso } from '../types/aviso';


export interface AvisosStore {
    avisos: Aviso[] | undefined;
    setAvisos: (avisos: Aviso[]) => void;
    delAvisos: () => void;
    addAviso: (aviso: Aviso) => void;
    delAviso: (aviso: Aviso) => void;
}

export const useAvisosStore = create(
    persist<AvisosStore>(
        (set, get) => ({
            avisos: undefined,
            setAvisos: (avisos: Aviso[]) => set({ avisos }),
            delAvisos: () => set({ avisos: undefined }),
            addAviso: (tarefa: Aviso) => set({ avisos: [...get()?.avisos as Aviso[], tarefa] }),
            delAviso: (tarefa: Aviso) => set({ avisos: get()?.avisos?.filter((f) => f?.id !== tarefa?.id) }),
        }),
        {
            name: 'dcx:intra:avisos', // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        },
    ),
);