import { RecordListOptions } from "pocketbase";
import { Aviso } from "../types/aviso";
import { pb } from "./usePocketBase";


export const useAvisosDB = () => {

    const allAvisos = async (options?: RecordListOptions) => {
        return (await pb.collection('avisos').getList<Aviso>(1, 999, { filter: `finalizado=${false}&${options?.filter}`, ...options }))?.items

    }

    return {
        allAvisos,
    }
}