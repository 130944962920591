import { AlertProps, Box, Flex, Icon, chakra } from "@chakra-ui/react";
import { FiX } from "react-icons/fi";
import { IoMdAlert } from "react-icons/io";

export interface DcxAlert extends Partial<AlertProps> {
    onClose?: () => void,
}

export const DcxAlert = ({ onClose, ...props }: DcxAlert) => {


    return (
        <Flex
            w="full"
            bg="transparent"
            rounded="lg"
            _dark={{
                bg: "transparent",
            }}
            shadow="md"
            alignItems="center"
            justifyContent="center"
        >
            <Flex
                position={`relative`}
                maxW="xl"
                w="full"
                mx="auto"
                bg="white"
                _dark={{
                    bg: "gray.800",
                }}
                rounded="lg"
                overflow="hidden"
                borderWidth={1}
                borderColor={`${props.color}`}
            >
                <Icon
                    position={`absolute`}
                    top={1}
                    right={1}
                    as={FiX}
                    color={`gray.600`}
                    _hover={{
                        cursor: 'pointer'
                    }}
                    onClick={onClose}
                />
                <Flex justifyContent="center" alignItems="center" w={20} bg={props.status === 'success' ? "green.500" : props.status === 'info' ? "cyan.500" : props.status === 'warning' ? "orange.500" : props.status === 'error' ? "red.500" : props.status === 'loading' ? "blue.300" : 'gray.100'}>
                    <Icon as={IoMdAlert} color="white" boxSize={6} />
                </Flex>

                <Box mx={-3} py={2} px={4}>
                    <Box mx={3}>
                        <chakra.span
                            color={props.status === 'success' ? "green.500" : props.status === 'info' ? "cyan.500" : props.status === 'warning' ? "orange.500" : props.status === 'error' ? "red.500" : props.status === 'loading' ? "blue.300" : 'gray.100'}
                            _dark={{
                                color: props.status === 'success' ? "green.500" : props.status === 'info' ? "cyan.500" : props.status === 'warning' ? "orange.500" : props.status === 'error' ? "red.500" : props.status === 'loading' ? "blue.300" : 'gray.100',
                            }}
                            fontWeight="bold"
                        >
                            {props.status === 'success' ? 'Tudo certo!' : props.status === 'loading' ? 'Carregando...' : props.status === 'warning' || props.status === 'error' || props.status === 'info' ? 'Oops!' : ''}
                        </chakra.span>
                        <chakra.p
                            color="gray.600"
                            _dark={{
                                color: "gray.200",
                            }}
                            fontSize="sm"
                        >
                            {props?.title}
                        </chakra.p>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    )
}