
import { Chamado, expandChamado } from "../types/chamado";
import { useColletion } from "./usePocketBase";


export const useChamado = () => {
    const { getDocs } = useColletion('chamados');
    const getChamados = async () => {
        return (await getDocs<Chamado>(1, 9999, expandChamado))?.items;
    }

    return {
        getChamados,
    }
}