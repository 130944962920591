import { RecordListOptions } from "pocketbase";
import { Area } from "../types/area";
import { pb } from "./usePocketBase";


export const useAreasDB = () => {

    const allAreas = async (options?: RecordListOptions) => {
        return (await pb.collection('areas').getList<Area>(1, 999, { filter: `ativo=${false}&${options?.filter}`, ...options }))?.items

    }

    return {
        allAreas,
    }
}