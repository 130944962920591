import { useToast } from "@chakra-ui/react";
import { createContext, useRef } from "react";
import { v4 } from "uuid";
import { DcxAlert } from "../components/Alerts";

type statusAlert = 'success' | 'error' | 'warning' | 'info';

export interface IPropsAddAlert {
    message: string,
    status: statusAlert,
}
export interface AlertContextProps {
    success: (values: IPropsAddAlert['message']) => void,
    info: (values: IPropsAddAlert['message']) => void,
    error: (values: IPropsAddAlert['message']) => void,
    warning: (values: IPropsAddAlert['message']) => void,
}

export const AlertContext = createContext<AlertContextProps>({
    success: () => { },
    info: () => { },
    error: () => { },
    warning: () => { },
});


export const AlertProvider: React.FC<any> = ({ children }) => {



    let toast = useToast()
    let toastIdRef = useRef<any>(null)

    const close = () => {
        if (toastIdRef?.current) {
            toast.close(toastIdRef?.current)
        }
    }
    /*
        const closeAll = () => {
            // you may optionally pass an object of positions to exclusively close
            // keeping other positions opened
            // e.g. `{ positions: ['bottom'] }`
            toast.closeAll()
        } */

    const add = ({ status = "success", message }: IPropsAddAlert) => {
        const id = v4();


        toastIdRef.current = toast({
            id,
            title: message,
            status,
            isClosable: true,
            position: 'top-right',
            variant: 'top-accent',
            duration: 5000,
            onCloseComplete: () => toastIdRef.current = null,
            //@ts-ignore
            render: (props) => <DcxAlert {...props} onClose={close} />
        })

    }

    const success = (message: string) => {
        if (!message) return;
        add({ message, status: 'success' })
    }

    const info = (message: string) => {
        if (!message) return;
        add({ message, status: 'info' })
    }

    const error = (message: string) => {
        if (!message) return;
        add({ message, status: 'error' })
    }

    const warning = (message: string) => {
        if (!message) return;
        add({ message, status: 'warning' })
    }

    return (
        <AlertContext.Provider
            value={{
                success,
                info,
                error,
                warning,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}