/* eslint-disable */
/* eslint-disable no-console */
import { ChakraProvider, ColorModeScript, theme } from "@chakra-ui/react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import { AlertProvider } from "./contexts/AlertContext"
import { AuthProvider } from "./contexts/AuthContext"


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  /*  <React.StrictMode> */
  <div>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      {/*  <ColorModeSwitcher
        position={`sticky`}
        top={1}
        right={1}
      justifySelf="flex-end" /> */}
      <AlertProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </AlertProvider>
    </ChakraProvider>
  </div>
  /* </React.StrictMode> */,
)

